import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { signIn, signOut, useSession } from 'next-auth/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Layout from '@/components/layout';

export default function Login() {
    const [session] = useSession();
    const router = useRouter();

    // if a user is logged in, never show them this page
    useEffect(() => {
        if (session) {
            router.push('/account')
        }
    }, [session, router])

    return (
        <Layout>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-xl-4 my-6">
                        <div className="text-center">
                            {!session && <>
                                <a
                                    href={`/api/auth/signin`}
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        signIn();
                                    }}
                                >
                                    <i className="fe fe-user mr-1"></i> Login
                                </a>
                            </>}

                            {session && <>
                                <a
                                    href={`/api/auth/signout`}
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        signOut({ callbackUrl: 'signOut' });
                                    }}
                                >
                                    <i className="fe fe-log-out"></i> Logout
                                </a>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export async function getStaticProps({ locale }) {
    return {
        props: {
            ...await serverSideTranslations(locale, ['common', 'menu'])
        }
    }
}
